<template>
  <Table border rounded :data="data" :columns="columns" :cell-class="handleCellClass">
    <template #[`cell-${EXCEEDING_PRICE_PRODUCTS_HEADERS.PRICE_PER_QUANTITY}`]="{ rowData: { pricePerQuantity } }">
      <p :style="{ color: '#E52044', fontWeight: '500' }">{{ pricePerQuantity }}</p>
    </template>
    <template v-if="!isSingleView" #[`cell-${EXCEEDING_PRICE_PRODUCTS_HEADERS.COMPARISON}`]="{ rowIndex }">
      <div class="d-flex justify-content-end" :style="{ width: '85%' }">
        <el-tooltip
          placement="top"
          :content="$t('tasks.mapProductTask.exceedingPriceModal.table.comparisonTooltip')"
          popper-class="compare-tooltip"
        >
          <Button type="icon" class="compare-btn" @click="$emit('on-compare-click', rowIndex)">
            <DocumentComparisonIcon :size="20" />
          </Button>
        </el-tooltip>
      </div>
    </template>
  </Table>
</template>
<script>
import { ref, computed } from 'vue';

import { Button, Table } from '@/modules/core';
import { formatMoneyOrDash, formatDateOrDash } from '@/modules/purchase-management/purchaseManagementFormatters';
import { DocumentComparisonIcon } from '@/assets/icons';

import { EXCEEDING_PRICE_PRODUCTS_HEADERS, getExceedingPriceProductsColumns } from './mapProductsTaskColumns';

export default {
  name: 'ExceedingPriceProductsTable',
  components: { Table, Button, DocumentComparisonIcon },
  props: {
    isSingleView: { type: Boolean, default: false },
    product: { type: Object, default: null },
    orderItems: { type: Array, default: () => [] },
  },
  emits: ['on-compare-click'],
  setup(props) {
    const columns = getExceedingPriceProductsColumns(props.isSingleView);
    const activeRowIndex = ref(-1);

    const data = computed(() =>
      props.orderItems.map((orderItem) => {
        const price = orderItem?.price * 100;
        return {
          [EXCEEDING_PRICE_PRODUCTS_HEADERS.SKU]: props.product?.sku,
          [EXCEEDING_PRICE_PRODUCTS_HEADERS.PRODUCT]: props.product?.name,
          [EXCEEDING_PRICE_PRODUCTS_HEADERS.ORDER_DATE]: formatDateOrDash(orderItem?.orderDate),
          [EXCEEDING_PRICE_PRODUCTS_HEADERS.PRICE_PER_QUANTITY]: formatMoneyOrDash(price),
          [EXCEEDING_PRICE_PRODUCTS_HEADERS.QUANTITY]: orderItem?.quantity,
          [EXCEEDING_PRICE_PRODUCTS_HEADERS.TOTAL]: formatMoneyOrDash(orderItem?.quantity * price),
        };
      })
    );

    const handleCellClass = (rowIndex) => {
      if (rowIndex === activeRowIndex.value) {
        return 'active-row';
      }
    };

    return {
      EXCEEDING_PRICE_PRODUCTS_HEADERS,
      data,
      columns,
      handleCellClass,
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  tr {
    .compare-btn {
      visibility: hidden;
    }
    .active-row {
      .compare-btn {
        visibility: visible;
        background-color: #ecf0f3;
      }
    }
    &:hover {
      .compare-btn {
        visibility: visible;
      }
    }
  }
}
</style>
<style lang="scss">
.compare-tooltip {
  &.el-tooltip__popper {
    padding: 4px;
  }
}
</style>
