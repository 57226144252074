<template>
  <SingularTaskLayout
    :title="
      $t('tasks.mapProductTask.title', {
        documentNumber: $t('tasks.mapProductTask.documentDetails.referenceNumber', {
          documentNumber: document.documentNumber,
        }),
      })
    "
    :task="task"
    :validate-task-before-complete-hook="validateTaskHook"
    :is-loading-task="isLoadingTask"
    @complete-task="onTaskComplete"
  >
    <template #content>
      <div v-loading="documentLoading" class="w-100 h-100 d-flex task-container">
        <div class="d-flex flex-column w-50 form-container">
          <MapProductsDocumentDetails :document="document" />
          <MapProductsTable :products="productsWithReason" @on-product-click="onProductClick" />
        </div>
        <div class="d-flex flex-column gap-4 w-50 doc-container">
          <p class="document-title">{{ $t('tasks.mapProductTask.originDocument') }}</p>
          <FileViewer v-if="documentFilePathUrl" :url="documentFilePathUrl" />
        </div>
      </div>
      <ExceedingPriceModal
        v-if="chosenProduct"
        :visible="!!chosenProduct"
        :document="document"
        :product="chosenProduct"
        :business-id="document?.businessId"
        @on-close="onCloseExceedingPriceModal"
        @on-submit="onSubmitExceedingPriceModal"
      />
    </template>
    <template #play-mode-indicator>
      <slot name="play-mode-indicator"></slot>
    </template>
  </SingularTaskLayout>
</template>
<script>
import { ref, computed } from 'vue';

import { FileViewer } from '@clarityo/ui-components';
import { usePatchTaskNew2 } from '@/modules/tasks';

import SingularTaskLayout from '../../../tasks/task/components/SingularTaskLayout';
import { useDocumentForProductMapTask } from '../../compositions/document/useDocumentForProductMapTask';
import { useProductsForMapTask } from '../../compositions/task/useProductsForMapTask';
import MapProductsDocumentDetails from './MapProductsDocumentDetails.vue';
import MapProductsTable from './MapProductsTable.vue';
import ExceedingPriceModal from './ExceedingPriceModal.vue';

export default {
  name: 'MapProductsTask',
  components: { FileViewer, MapProductsTable, SingularTaskLayout, ExceedingPriceModal, MapProductsDocumentDetails },
  props: {
    task: { type: Object, required: true },
    isLoadingTask: { type: Boolean, required: true },
  },
  emits: ['on-task-complete'],
  setup(props, { emit }) {
    const chosenProduct = ref(null);

    const documentId = computed(() => props.task?.data?.documentId);
    const productsFromTask = computed(() => props.task?.data?.products ?? []);

    const { document, loading: documentLoading } = useDocumentForProductMapTask(documentId);
    const { products } = useProductsForMapTask(
      computed(() => productsFromTask.value.map(({ productId }) => productId))
    );
    const documentFilePathUrl = computed(() => document.value?.filePathUrl);

    const productsWithReason = computed(() =>
      products.value.map((product) => {
        const reason = productsFromTask.value.find(({ productId }) => productId === product.id)?.reason;

        return {
          ...product,
          reason,
        };
      })
    );

    const { patchTask } = usePatchTaskNew2();
    const validateTaskHook = async () => ({ errors: [], warnings: [] });
    const onTaskComplete = () => emit('on-task-complete');

    const onCloseExceedingPriceModal = () => {
      chosenProduct.value = null;
    };

    const onSubmitExceedingPriceModal = ({ decision }) => {
      const newData = { ...props.task?.data };
      newData.products = newData.products.map((product) => {
        if (chosenProduct.value?.id === product.productId) {
          return {
            ...product,
            reason: decision,
          };
        }
        return product;
      });

      patchTask({ taskId: props.task?.id, patchParams: { data: newData } });

      onCloseExceedingPriceModal();
    };

    const onProductClick = (index) => {
      const product = products.value[index];
      chosenProduct.value = product;
    };

    return {
      document,
      chosenProduct,
      documentLoading,
      productsWithReason,
      documentFilePathUrl,
      onProductClick,
      onTaskComplete,
      validateTaskHook,
      onCloseExceedingPriceModal,
      onSubmitExceedingPriceModal,
    };
  },
};
</script>
<style lang="scss" scoped>
.task-container {
  gap: 3rem;
}
.form-container {
  gap: 2.5rem;
}
.doc-container {
  min-height: 60vh;
}
.document-title {
  font-size: 1.25rem;
  font-weight: 700;
}
</style>
