<template>
  <div class="d-flex flex-column gap-5">
    <ExceedingPriceProductsTable :order-items="[orderItem]" :product="product" :is-single-view="true" />
    <div :style="{ height: '32rem' }">
      <FileViewer v-if="documentFilePathUrl" :url="documentFilePathUrl" />
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';

import { FileViewer } from '@clarityo/ui-components';

import ExceedingPriceProductsTable from './ExceedingPriceProductsTable.vue';

export default {
  name: 'ExceedingPriceProductDocument',
  components: { FileViewer, ExceedingPriceProductsTable },
  props: {
    product: { type: Object, default: null },
    orderItem: { type: Object, default: null },
    document: { type: Object, default: null },
  },
  emits: [''],
  setup(props) {
    const documentFilePathUrl = computed(() => props.document?.filePathUrl);

    return {
      documentFilePathUrl,
    };
  },
};
</script>
<style lang="scss" scoped></style>
