<template>
  <div class="d-flex flex-column gap-4 border rounded p-4 product-details">
    <p class="product-details-title">{{ $t('tasks.mapProductTask.documentDetails.title') }}</p>
    <div class="d-flex flex-column gap-4">
      <div v-for="(detail, index) in details" :key="`product-detail-${index}`" class="d-flex gap-2">
        <p class="product-detail-row-title">{{ detail.title }}</p>
        <p>{{ detail.value }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, getCurrentInstance } from 'vue';

import { formatDate } from '@/modules/purchase-management/purchaseManagementFormatters';

export default {
  components: {},
  props: {
    document: { type: Object, default: null },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;

    const details = computed(() => [
      { title: root.$t('tasks.mapProductTask.documentDetails.supplier'), value: props.document?.supplier?.name },
      {
        title: root.$t('tasks.mapProductTask.documentDetails.createdDate'),
        value: formatDate(props.document?.createdAt),
      },
      {
        title: root.$t('tasks.mapProductTask.documentDetails.reference'),
        value: root.$t('tasks.mapProductTask.documentDetails.referenceNumber', {
          documentNumber: props.document?.documentNumber,
        }),
      },
    ]);

    return {
      details,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.product-details {
  .product-details-title {
    font-size: 1.25rem;
    font-weight: 700;
  }

  .product-detail-row-title {
    width: 11rem;
    color: $typography-secondary;
  }
}
</style>
