<template>
  <Modal
    :visible="visible"
    :top="'4vh'"
    custom-class="exceeding-price-modal"
    :title="$t('tasks.mapProductTask.exceedingPriceModal.title')"
    :submit-disabled="!decision"
    @on-close="$emit('on-close')"
    @on-submit="onSubmit"
  >
    <template #subtitle>
      <div class="d-flex gap-2 align-items-center">
        <p>{{ product?.name }}</p>
        <DotIcon />
        <p>{{ product?.business?.name }}</p>
      </div>
    </template>
    <div :style="{ borderTop: '1.5px solid #E3E4E6', borderBottom: '1.5px solid #E3E4E6', padding: '1.5rem' }">
      <div class="d-flex flex-column gap-5 w-100">
        <div class="d-flex flex-column gap-4">
          <div class="d-flex gap-5">
            <div
              :style="{
                borderRadius: '0.5rem',
                border: '1px solid #E3E4E6',
                minWidth: '650px',
                padding: '1.5rem',
              }"
            >
              <div class="d-flex flex-column gap-5">
                <div :style="{ height: '2rem' }">
                  <p :style="{ fontSize: '1.25rem', fontWeight: '700', lineHeight: '1.25rem', color: '#1F284D' }">
                    {{ $t('tasks.mapProductTask.exceedingPriceModal.currentOrderDetails') }}
                  </p>
                </div>
                <ExceedingPriceProductDocument :order-item="currentOrderItem" :product="product" :document="document" />
              </div>
            </div>
            <div
              v-loading="orderItemsLoading"
              :style="{
                borderRadius: '0.5rem',
                border: '1px solid #E3E4E6',
                minWidth: '650px',
                padding: '1.5rem',
              }"
            >
              <div class="d-flex flex-column gap-5">
                <div class="d-flex w-100 justify-content-between">
                  <p :style="{ fontSize: '1.25rem', fontWeight: '700', lineHeight: '1.25rem', color: '#1F284D' }">
                    {{ $t('tasks.mapProductTask.exceedingPriceModal.lastOrderDetails') }}
                    {{
                      `${
                        orderItemIndexToCompare != null ? `(${orderItemIndexToCompare + 1}/${orderItems.length})` : ''
                      }`
                    }}
                  </p>
                  <div class="d-flex gap-2 align-items-center">
                    <Button
                      type="icon"
                      class="p-0"
                      :disabled="!orderItemIndexToCompare"
                      @click="() => onClickChevron('right')"
                    >
                      <ChevronIcon direction="right" />
                    </Button>
                    <Button
                      type="icon"
                      class="p-0"
                      :style="{ marginRight: '0px' }"
                      :disabled="orderItemIndexToCompare + 1 === orderItems.length"
                      @click="() => onClickChevron('left')"
                    >
                      <ChevronIcon direction="left" />
                    </Button>
                    <el-select v-model="orderItemIndexToCompare" size="small" :style="{ width: '8.75rem' }">
                      <div v-for="(dateOption, index) in selectDateOptions" :key="dateOption.value">
                        <el-option
                          v-if="index !== selectDateOptions.length - 1"
                          :label="dateOption.label"
                          :value="dateOption.value"
                          :disabled="index === 0"
                        />
                        <div v-else>
                          <hr :style="{ margin: '0.5rem 0' }" />
                          <el-option :label="dateOption.label" :value="dateOption.value" />
                        </div>
                      </div>
                    </el-select>
                  </div>
                </div>
                <div v-if="orderItemIndexToCompare === null">
                  <div class="d-flex flex-column gap-5">
                    <ExceedingPriceProductsTable
                      :order-items="orderItems"
                      :product="product"
                      @on-compare-click="(index) => (orderItemIndexToCompare = index)"
                    />
                    <div :style="{ width: '450px' }" class="d-flex flex-column gap-2">
                      <p :style="{ fontSize: '0.875rem', fontWeight: '500', lineHeight: '1.25rem', color: '#1F284D' }">
                        {{ $t('tasks.mapProductTask.exceedingPriceModal.bomTable.title') }}
                      </p>
                      <BomTable :bom="product?.bom" />
                    </div>
                  </div>
                </div>
                <div v-else v-loading="documentToCompareToLoading">
                  <ExceedingPriceProductDocument
                    :order-item="orderItemToCompareTo"
                    :product="product"
                    :document="documentToCompareTo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center gap-4"
          :style="{
            borderRadius: '0.5rem',
            border: '1px solid #E3E4E6',
            height: '7.5rem',
            padding: '2rem 0rem',
            backgroundColor: '#FBFBFB',
          }"
        >
          <p :style="{ fontSize: '1.25rem', fontWeight: '700', lineHeight: '1.25rem', color: '#1F284D' }">
            {{ $t('tasks.mapProductTask.exceedingPriceModal.action') }}
          </p>
          <div>
            <el-radio v-model="decision" :label="DECISIONS.IGNORE">{{
              $t('tasks.mapProductTask.exceedingPriceModal.actions.ignore')
            }}</el-radio>
            <el-radio v-model="decision" :label="DECISIONS.PROBLEM_WITH_UOM">{{
              $t('tasks.mapProductTask.exceedingPriceModal.actions.problemWithUom')
            }}</el-radio>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import { ref, computed, getCurrentInstance } from 'vue';

import { Button } from '@/modules/core';
import { DotIcon, ChevronIcon } from '@/assets/icons';

import Modal from '../../../catalog/components/Modal.vue';
import ExceedingPriceProductsTable from './ExceedingPriceProductsTable.vue';
import { useLatestOrderItemsForMapTask } from '../../compositions/task/useLatestOrderItemsForMapTask';
import { useDocumentFilePath } from '../../compositions/task/useDocumentFilePath';
import ExceedingPriceProductDocument from './ExceedingPriceProductDocument.vue';
import BomTable from './BomTable.vue';

const PRODUCTS_TO_RETURN = 5;
export const DECISIONS = {
  IGNORE: 'ignore',
  PROBLEM_WITH_UOM: 'problemWithUom',
};

export default {
  name: 'ExceedingPriceModal',
  components: {
    Modal,
    Button,
    BomTable,
    DotIcon,
    ChevronIcon,
    ExceedingPriceProductsTable,
    ExceedingPriceProductDocument,
  },
  props: {
    visible: { type: Boolean, default: false },
    product: { type: Object, default: () => ({}) },
    businessId: { type: String, default: null },
    document: { type: Object, default: null },
  },
  emits: ['on-close', 'on-submit'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const orderItemIndexToCompare = ref(null);
    const decision = ref();

    const variablesToRetrieveOrderItems = computed(() => ({
      businessId: props.businessId,
      productId: props.product?.id,
      limit: PRODUCTS_TO_RETURN + 1, // We need a extra one just in case the first 5 are one of the current ones.
    }));
    const { orderItems, loading: orderItemsLoading } = useLatestOrderItemsForMapTask(variablesToRetrieveOrderItems);

    const filterIfOrderItemMatchesCurrentDocument = (orderItem) => {
      if (!props.document) {
        return true;
      }

      return orderItem?.orderSource?.documentNew2?.id !== props.document?.id;
    };

    const currentOrderItem = computed(() => {
      const currentItem = props.document?.items?.find(({ productId }) => productId === props.product.id);
      return {
        ...currentItem,
        orderDate: props.document?.issueDate,
      };
    });
    const filteredOrderItems = computed(() => orderItems.value.filter(filterIfOrderItemMatchesCurrentDocument));
    const orderItemToCompareTo = computed(() => filteredOrderItems.value[orderItemIndexToCompare.value]);

    const { document: documentToCompareTo, loading: documentToCompareToLoading } = useDocumentFilePath(
      computed(() => {
        if (!orderItemToCompareTo.value) {
          return null;
        }
        return orderItemToCompareTo.value?.orderSource?.documentNew2?.id;
      })
    );

    const selectDateOptions = computed(() => {
      const options = filteredOrderItems.value.map((orderItem, index) => ({
        value: index,
        label: orderItem.orderDate,
      }));
      return [
        { value: 'title', label: root.$t('tasks.mapProductTask.exceedingPriceModal.compareDocuments') },
        ...options,
        { value: null, label: root.$t('tasks.mapProductTask.exceedingPriceModal.allOrders') },
      ];
    });

    const onClickChevron = (direction) => {
      if (direction === 'right') {
        orderItemIndexToCompare.value -= 1;
      }
      if (direction === 'left') {
        if (orderItemIndexToCompare.value === null) {
          orderItemIndexToCompare.value = 0;
        } else {
          orderItemIndexToCompare.value += 1;
        }
      }
    };

    const onSubmit = () => {
      emit('on-submit', { decision: decision.value });
    };

    return {
      DECISIONS,
      decision,
      currentOrderItem,
      orderItemsLoading,
      selectDateOptions,
      documentToCompareTo,
      orderItemToCompareTo,
      orderItemIndexToCompare,
      documentToCompareToLoading,
      orderItems: filteredOrderItems,
      onSubmit,
      onClickChevron,
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .exceeding-price-modal {
    @media (max-width: 1800px) {
      width: 1400px !important;
    }
    @media (min-width: 1800px) {
      width: 1856px !important;
    }
  }
}
</style>
