<template>
  <div class="d-flex flex-column gap-4">
    <p class="map-products-title">{{ $t('tasks.mapProductTask.table.title', { count: products?.length }) }}</p>
    <div>
      <TableLoadingSkeleton v-if="false" />
      <Table v-else border rounded :data="data" :columns="columns" :show-index="true" @row-click="onRowClick">
        <template #[`cell-${MAP_PRODUCTS_TASK_HEADERS.REASON}`]="{ rowData: { reason } }">
          <Tag :type="getTagType(reason)">{{ $t(`tasks.mapProductTask.table.reasons.${reason}`) }}</Tag>
        </template>
      </Table>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';

import { Tag, Table, TableLoadingSkeleton } from '@/modules/core/components';

import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';
import { MAP_PRODUCTS_TASK_HEADERS, getProductsMapColumns } from './mapProductsTaskColumns';
import { DECISIONS } from './ExceedingPriceModal.vue';

export default {
  name: 'MapProductsTable',
  components: { Tag, Table, TableLoadingSkeleton },
  props: { products: { type: Array, default: () => [] } },
  emits: ['on-product-click'],
  setup(props, { emit }) {
    const columns = getProductsMapColumns();

    const data = computed(() =>
      props.products.map((product) => ({
        sku: product.sku ?? MISSING_DATA_TEXT,
        product: product.name,
        reason: product.reason,
      }))
    );

    const onRowClick = (index) => {
      emit('on-product-click', index);
    };

    const getTagType = (reason) => {
      const allDecisions = Object.values(DECISIONS);
      if (allDecisions.includes(reason)) {
        return 'neutral';
      }

      return 'warning';
    };

    return {
      data,
      MAP_PRODUCTS_TASK_HEADERS,
      columns,
      onRowClick,
      getTagType,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.map-products-title {
  color: #131416;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
}
</style>
